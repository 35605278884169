document.addEventListener("DOMContentLoaded", function() {


//Bootstrap Tooltips
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
});


// Obtener los botones y el div del video
const button1 = document.getElementById("button1");
const button2 = document.getElementById("button2");
const button3 = document.getElementById("button3");
const videoContainer = document.getElementById("video-container");
const videoFrame = document.getElementById("video-frame");

// Función para mostrar un video específico en el div
function mostrarVideo(id) {
    // Ocultar el div del video si ya había un video mostrándose
    videoContainer.style.display = "none";

    // Cambiar la URL del iframe según el botón clickeado
    if (id === 1) {
      videoFrame.src = "https://www.youtube.com/embed/Rx328-OaAiI";
    } else if (id === 2) {
      videoFrame.src = "https://www.youtube.com/embed/BJ8EwnWVN1E";
    } else if (id === 3) {
      videoFrame.src = "https://www.youtube.com/embed/7hGKEZGsVN0";
    }

    // Mostrar el div del video
    videoContainer.style.display = "block";

    // Remover la clase "active" de todos los botones
    button1.classList.remove("active");
    button2.classList.remove("active");
    button3.classList.remove("active");

    // Agregar la clase "active" al botón clickeado
    if (id === 1) {
      button1.classList.add("active");
    } else if (id === 2) {
      button2.classList.add("active");
    } else if (id === 3) {
      button3.classList.add("active");
    }
  }

  // Mostrar el primer video al cargar la página
  mostrarVideo(1);

  // Asignar un listener de click a cada botón
  button1.addEventListener("click", function() {
    mostrarVideo(1);
  });

  button2.addEventListener("click", function() {
    mostrarVideo(2);
  });

  button3.addEventListener("click", function() {
    mostrarVideo(3);
  });

  // crear 3 botones con HTML, CSS y JavaScript para mostrar diferentes contenidos al hacer clic en cada botón
  const btn1 = document.getElementById("button1");
  const btn2 = document.getElementById("button2");
  const btn3 = document.getElementById("button3");

  const div1 = document.getElementById("div1");
  const div2 = document.getElementById("div2");
  const div3 = document.getElementById("div3");

  div1.style.display = "block";

  btn1.addEventListener("click", () => {
    div1.style.display = "block";
    div2.style.display = "none";
    div3.style.display = "none";
  });

  btn2.addEventListener("click", () => {
    div1.style.display = "none";
    div2.style.display = "block";
    div3.style.display = "none";
  });

  btn3.addEventListener("click", () => {
    div1.style.display = "none";
    div2.style.display = "none";
    div3.style.display = "block";
  });

  ////////link circle hero////////
 
  // Array de videos con su texto y enlaces
  var videoData = [
    {
      videoName: "Ver corto 'Aislamiento'",
      link: "https://www.youtube.com/watch?v=MPiSlNUK2Fs&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver corto 'Trabajadores de salud'",
      link: "https://www.youtube.com/watch?v=zvCDV4i6i5s&t=5s&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver documental 'Mani'",
      link: "https://www.youtube.com/watch?v=pQ7d80T6Wh0&t=9s&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver spot 'Violencia económica'",
      link: "https://www.youtube.com/watch?v=ztRjl1h-Ur0&t=43s&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver videodanza",
      link: "https://www.youtube.com/watch?v=r1b7BkEahFk&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver documental 'Moi y Mirko'",
      link: "https://www.youtube.com/watch?v=uyq3z8R4DX0&t=26s&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver documental 'Brenda'",
      link: "https://www.youtube.com/watch?v=7hGKEZGsVN0&t=3s&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver videoclip 'Fiesta y Rutina'",
      link: "https://www.youtube.com/watch?v=DCBrdwcsrM0&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver corto 'El Pico'",
      link: "https://www.youtube.com/watch?v=Rx328-OaAiI&t=48s&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver corto 'Nalá'",
      link: "https://www.youtube.com/watch?v=BJ8EwnWVN1E&t=113s&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver trailer de una obra de teatro",
      link: "https://www.youtube.com/watch?v=X3J-h1qoW-I&t=17s&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver documental sobre discapacidad visual",
      link: "https://www.youtube.com/watch?v=x-MbyoCuN5w&t=227s&ab_channel=BuiaFilms"
    },
    {
      videoName: "Ver spot promocional 'CEM'",
      link: "https://www.youtube.com/watch?v=6EVx4l_M8Sc&ab_channel=BuiaFilms"
    }
  ];

  // Obtener elementos del DOM
  var videoText = document.getElementById("videoText");
  var videoLink = document.getElementById("videoLink");
  var circleContent = document.getElementById("circleContent");
  var circleIcon = document.getElementById("circleIcon");
  var contentVideos = document.getElementById("content-videos");
  var circle = document.querySelector(".hero-shape");

  // Variable para rastrear los videos ya reproducidos
  var playedVideos = [];

  // Texto inicial
  var initialText = '<i class="fas fa-hand-pointer"></i> Probar videorockola';

  // Variable para rastrear el estado del clic
  //var clicked = false;

  // Actualizar el texto inicial
  circleContent.innerHTML = initialText;
  

  // Agregar evento de clic al círculo
  document.querySelector(".hero-shape").addEventListener("click", function() {
    // Verificar si todos los videos ya se reprodujeron
    if (playedVideos.length === videoData.length) {
      playedVideos = []; // Restablecer los videos reproducidos a un array vacío
      return;
    }

    // Obtener un índice aleatorio
    var randomIndex = getRandomIndex();

    // Obtener el nombre del video, el enlace y el texto asociado aleatorio
    var randomVideoName = videoData[randomIndex].videoName;
    var randomLink = videoData[randomIndex].link;

    // Obtener un índice aleatorio
   // var randomIndex = Math.floor(Math.random() * videoData.length);
    
    // Obtener el nombre del video, el enlace y el texto asociado aleatorio
    var randomVideoName = videoData[randomIndex].videoName;
    var randomLink = videoData[randomIndex].link;
    
    // Actualizar el texto y el enlace
    videoText.textContent = randomVideoName;
    videoLink.href = randomLink;

    // Detener la animación del círculo
    circle.style.animation = "none";
    circle.style.borderRadius="50%";
    circle.style.transform = "rotate(0deg)";

    // Actualizar el ícono y ocultar el texto
    circleIcon.style.display = "flex";
    circleContent.style.display = "none";
    contentVideos.style.display="block";

    // Agregar el índice del video a los ya reproducidos
    playedVideos.push(randomIndex);
  });

  
// Función para obtener un índice aleatorio que no se haya reproducido
function getRandomIndex() {
  var remainingVideos = [];

  // Filtrar los videos que aún no se han reproducido
  for (var i = 0; i < videoData.length; i++) {
    if (!playedVideos.includes(i)) {
      remainingVideos.push(i);
    }
  }

  // Obtener un índice aleatorio de los videos restantes
  var randomIndex = Math.floor(Math.random() * remainingVideos.length);

  // Devolver el índice del video seleccionado
  return remainingVideos[randomIndex];
}

});

//////////////////scroll transition////////////////////////

document.addEventListener("DOMContentLoaded", function(event) {
  var sections = document.getElementsByClassName("scroll-transition");

  function fadeInElements() {
    for (var i = 0; i < sections.length; i++) {
      var section = sections[i];
      var sectionTop = section.getBoundingClientRect().top;
      var windowBottom = window.innerHeight;

      if (sectionTop < windowBottom) {
        section.classList.add("fade-in");
      }
    }

    // Deshabilitar el evento de scroll una vez que se ha activado la animación en todas las secciones
    if (document.getElementsByClassName("fade-in").length === sections.length) {
      window.removeEventListener("scroll", fadeInElements);
    }
  }

  window.addEventListener("scroll", fadeInElements);
});

